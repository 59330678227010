import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Directive, Inject, Input, NgModule } from '@angular/core';
const defaultOptions = {
  style: 'primary',
  size: 'medium',
  selected: false
};
/**
 * Fly-Button - Directive for common styling of buttons
 *
 * @example Basic default use (primary style)
 * <button flyButton
 *   (click)="onClickMyButton()">Button text</button>
 *
 * @example Button with special styling (danger & large)
 * <button flyButton
 *   [options]="{
 *     style: 'danger',
 *     size: 'large'
 *   }"
 *   (click)="onClickMyDangerousButton()">Do Dangerous Thing</button>
 *
 * @example Button with special styling (pill)
 * <button flyButton
 *   [options]="{
 *     style: 'pill',
 *     selected: true
 *   }"
 *   (click)="onClickMyButton()">
 *   <fly-icon [icon]="Airports.ToweredServices"
 *     class="icon-left"></fly-icon>
 *   KABE
 * </button>
 *
 * Note: Styling exists under style-variables/scss/_directives.scss
 */
class FlyButtonDirective {
  constructor(elementRef, renderer, document) {
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.document = document;
    this.options = defaultOptions;
  }
  ngOnInit() {
    this.updateOptions(undefined, this.options);
  }
  ngOnChanges(changes) {
    if ('options' in changes) {
      const oldOptions = changes['options'].previousValue;
      const newOptions = {
        ...defaultOptions,
        ...changes['options'].currentValue
      };
      this.options = newOptions;
      this.updateOptions(oldOptions, this.options);
    }
  }
  updateOptions(oldOptions, newOptions) {
    if (oldOptions?.selected !== newOptions?.selected) {
      if (newOptions?.selected === true) {
        this.renderer.addClass(this.elementRef.nativeElement, 'selected');
      } else {
        this.renderer.removeClass(this.elementRef.nativeElement, 'selected');
      }
    }
    if (oldOptions?.size !== newOptions?.size) {
      if (newOptions?.style !== 'card' && newOptions?.style !== 'pill') {
        if (oldOptions?.size != null) {
          this.renderer.removeClass(this.elementRef.nativeElement, `fly-button-size-${oldOptions.size}`);
        }
        if (newOptions?.size != null) {
          this.renderer.addClass(this.elementRef.nativeElement, `fly-button-size-${newOptions.size}`);
        }
      }
    }
    if (oldOptions?.style !== newOptions?.style) {
      if (newOptions?.style !== 'card' && newOptions?.style !== 'pill') {
        this.renderer.addClass(this.elementRef.nativeElement, 'fly-button-global');
      } else {
        this.renderer.removeClass(this.elementRef.nativeElement, 'fly-button-global');
      }
      if (oldOptions?.style != null) {
        this.renderer.removeClass(this.elementRef.nativeElement, `fly-button-${oldOptions.style}`);
      }
      if (newOptions?.style != null) {
        this.renderer.addClass(this.elementRef.nativeElement, `fly-button-${newOptions.style}`);
      }
    }
  }
  static {
    this.ɵfac = function FlyButtonDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyButtonDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(DOCUMENT));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FlyButtonDirective,
      selectors: [["button", "flyButton", ""]],
      inputs: {
        options: "options"
      },
      standalone: false,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyButtonDirective, [{
    type: Directive,
    args: [{
      selector: 'button[flyButton]',
      standalone: false
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.Renderer2
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], {
    options: [{
      type: Input
    }]
  });
})();
class FlyButtonModule {
  static {
    this.ɵfac = function FlyButtonModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyButtonModule, [{
    type: NgModule,
    args: [{
      declarations: [FlyButtonDirective],
      imports: [CommonModule],
      exports: [FlyButtonDirective]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/button
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlyButtonDirective, FlyButtonModule, defaultOptions };
