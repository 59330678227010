// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './environment.model';

export const environment: Environment = {
  name: 'DEV',
  production: false,
  garmin: {
    aviation: {
      coreDataHost: 'https://avcloud-coredata-api-test.aviation.garmin.com',
      gatewayHost: 'https://avcloud-gateway-test.garmin.com/webGateway',
      servicesHost: 'https://avcloud-api-test.garmin.com',

      // The following URL is for CloudFlare workers using a redirection method.
      // This URL is technically not linked to the application's URL though we expect it
      // to be the same most of the time.
      workerApiHost: 'https://test.pilotweb.garmin.com/api',

      coreData: {
        aircraftManagementService: {
          read: 'https://avcloud-coredata-api-test.aviation.garmin.com/aircraftManagementReadService',
          write:
            'https://avcloud-coredata-api-test.aviation.garmin.com/aircraftManagementWriteService',
        },
        customerService: {
          read: 'https://avcloud-coredata-api-test.aviation.garmin.com/customerReadService',
          write: 'https://avcloud-coredata-api-test.aviation.garmin.com/customerWriteService',
        },
        avdbService: {
          read: 'https://avcloud-coredata-api-test.aviation.garmin.com/avdbReadService',
          write: 'https://avcloud-coredata-api-test.aviation.garmin.com/avdbWriteService',
        },
        subscriptionService: {
          read: 'https://avcloud-coredata-api-test.aviation.garmin.com/subscriptionReadService',
          write: 'https://avcloud-coredata-api-test.aviation.garmin.com/subscriptionWriteService',
        },
        deviceService: {
          read: 'https://avcloud-coredata-api-test.aviation.garmin.com/deviceReadService',
          write: 'https://avcloud-coredata-api-test.aviation.garmin.com/deviceWriteService',
        },
      },
    },
  },
  // All environments will be pointing to Tealium Prod
  tealiumConfig: {
    account: 'garmin',
    profile: 'main',
    environment: 'prod',
  },
  trustArcConfig: {
    url: 'https://consent.trustarc.com/notice?domain=garmintest.com&c=teconsent&language=&country=fr&js=nj&noticeType=bb&gtm=1&text=true',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
